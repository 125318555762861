import React from "react";

const NotFound = () => {
  return (
    <main>
      <h2>404 Not Found!!!!!!! AHHHHHHHHHHHHHHH!!!!</h2>
    </main>
  )
}

export default NotFound;
