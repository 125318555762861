const fbaseConfig = {
  apiKey: "AIzaSyC3rip5Y-utAmNOil83mcbWsYN3ddVqks8",
  authDomain: "golblog-platform.firebaseapp.com",
  databaseURL: "https://golblog-platform.firebaseio.com",
  projectId: "golblog-platform",
  storageBucket: "golblog-platform.appspot.com",
  messagingSenderId: "376094567896"
};

export default fbaseConfig;
